import styled, { css } from 'styled-components';
import { Icons } from '@shopline/dashboard-ui';
import Text from 'components/Text';
import PropTypes from 'prop-types';

const AlertContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.PRIMARY_300};
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  border: border: 1px solid #5087E633;
`;

const flexShrink = css`
  flex-shrink: 0;
`;

const StyledText = styled(Text)`
  margin-left: 0.5rem;
`;

const Alert = ({ content }) => {
  return (
    <AlertContainer>
      <Icons.Solid.Alert
        className={flexShrink}
        color="PRIMARY_600"
        size="SMALL"
      />
      <StyledText fontType="Body" color="INK_800" weight="Semibold">
        {content}
      </StyledText>
    </AlertContainer>
  );
};

Alert.propTypes = {
  content: PropTypes.string.isRequired,
};

export default Alert;
